<template>
  <div class="book">
    <div class="page" v-for="item in items">
      <!-- Header -->
      <div>
        <!-- Left Content -->
        <div>
          <div class="d-flex align-center">
            <img :src="results.content_url + results.school_logo"
              style="max-height: 50px; max-width: 50px; border-radius: 10%; margin: 0 5px 0 10px;" alt="logo" contain>
            <span class="text--primary font-weight-bold text-xl"> {{ results.school_name }} </span>
          </div>
          <!-- <span class="d-block">اسم الطالب: علي سعد</span>
              <span class="d-block">الهاتف: 078032323232</span> -->
          <h2 style="margin-top: 30px"> اهلا بكم في {{ results.school_name }} </h2>
          <h2> يرجى تفعيل التطبيق عن طريق تصوير ال QR </h2>
          <div class="my-3" style="text-align: center;" v-if="results.school_id === '6309804080f853d69332730c'">
            <img :src="sama_altafawaq_qr" style="height: 75px; width: 75px">
          </div>
          <div class="my-3" style="text-align: center;"
            v-else-if="results.school_id === '6341512a347baba8b566a997'|| results.school_id === '6341505c347baba8b56693e1' || results.school_id === '63414ebf347baba8b5668057'">
            <img :src="mama_aymen_qr" style="height: 75px; width: 75px">
          </div>
          <div class="my-3" style="text-align: center;" v-else-if="results.school_id === '62ddcdb75ace0ba45e0bf9f3'">
            <img :src="almasara_qr" style="height: 75px; width: 75px">
          </div>
          <div class="my-3" style="text-align: center;" v-else>
            <img :src="qalam_qr" style="height: 75px; width: 75px">
          </div>
          <h2> لاجهزة ابل والاندرويد </h2>
          <h2> بعدها ادخال ايميل والرمز الخاص بالطالب</h2>
          <h2> اسم الطالب: {{item.account_name}}</h2>
          <h2> الايميل: {{item.account_email}}</h2>
          <h2> الرمز: {{item.account_password_show}}</h2>
        </div>
        <!-- Right Content -->
      </div>
    </div>
    <!-- Right Column: Invoice Action -->
    <!-- <v-col cols="12" md="3">
          <v-card>
            <v-card-text>
              <v-btn class="mb-3" color="secondary" block outlined @click="printInvoice"> طباعة </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row> -->
  </div>
</template>



<script>
import { mdiCurrencyUsd, mdiSendOutline } from '@mdi/js'

export default {
  //   components: {
  //     InvoiceSidebarSendInvoice,
  //     InvoiceSidebarAddPayment,
  //   },

  data() {
    return {
      qalam_qr: null,

      sama_altafawaq_qr: null,

      mama_aymen_qr: null,

      almasara_qr: null,

      invoiceData: null,
      paymentDetails: {},
      billInfo: {
        _id: null,
        student_id: null,
        desc: null,
        service_type: null,
        school_id: null,
        is_deleted: null,
        createdAt: null,
        updatedAt: null,
        salaryAmount: null,
        discountAmount: null,
        paymentAmount: null,
        currency: null,
        currencySymbol: ' IQD',
        nextPaymentDate: null,
        remainingAll: 0,
        student_name: null,
        service_name: null,
      },

      items: [],

      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
      },

      results: {},

      billAccountData: {},

      dateString: null,

      responseDB: [],

      statistic: {
        currency: 'IQD',
        currencySymbol: ' IQD',
        discount: null,
        payment: null,
        salary: null,
        remaining: null,
      },
    }
  },

  async created() {
    this.results = JSON.parse(localStorage.getItem('results'))
    this.qalam_qr = require(`@/assets/qalam_qr.png`)
    this.sama_altafawaq_qr = require(`@/assets/sama_altafawaq.jpg`)
    this.mama_aymen_qr = require(`@/assets/mama_aymen.jpg`)
    this.almasara_qr = require(`@/assets/almasara.jpg`)

    this.items = JSON.parse(localStorage.getItem('studentsInvoice'))

    this.printInvoice()
  },

  methods: {
    printInvoice() {
      window.print()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/apps/invoice.scss';

body {
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  font: 12pt 'Tahoma';
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  margin: 1cm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.subpage {
  padding: 1cm;
  border: 5px red solid;
  height: 256mm;
  outline: 2cm #ffeaea solid;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}

// @media print {
//   .v-application {
//     background: none !important;
//   }

//   .app-navigation-menu,
//   .v-app-bar,
//   .v-footer,
//   .product-buy-now,
//   .app-invoice-preview.row .col-12:last-of-type {
//     display: none !important;
//   }

//   .app-invoice-preview.row .col-12:first-child {
//     max-width: 100% !important;
//     flex: 1 !important;
//   }

//   .v-main,
//   .app-content-container {
//     padding: 0px !important;
//   }

//   .v-card {
//     box-shadow: none !important;
//   }

//   .app-invoice-preview {

//     .invoice-header,
//     .payment-details,
//     .invoice-total {
//       &.d-flex {
//         flex-direction: row !important;
//       }
//     }
//   }
// }
</style>
